import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4ee9923c = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _dd634c98 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _8fec7852 = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _7a4eaf67 = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _cca827f2 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _0236a28f = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _f41dd9e6 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _103c7ca8 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _0acb4231 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _1c75995c = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _03b21aa6 = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _76c9a0aa = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _1872ed87 = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _682516bf = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _1889b82c = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _48711851 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _28af7847 = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _f3f03998 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _f1699ad8 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _781a3ff1 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _94df266a = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _b44aad82 = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _4c3a8c64 = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _23f94714 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _d10adede = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _71514062 = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _387f8e6d = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _266ddbca = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _f43bef5c = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _1afb1d7b = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _308de26e = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _58160826 = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _53533f68 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _5be9b77c = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _716839d2 = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _3ce16607 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _554058d7 = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _2ff0c239 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _1a79428e = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _27bed885 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _038788cc = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _574444d3 = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _6a063dd4 = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _c38163bc = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _02536dcc = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _32d1aa34 = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _0735316e = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _488bbb46 = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _63fceb66 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _5a63f4d6 = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _cbddbdac = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _2d32281c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _76789162 = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _c7fbe498 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _25f78722 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _45bca4e0 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _2b7c21d5 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _2e8ede40 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _08752a24 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _54edec12 = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _b524af54 = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _749847e4 = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _94548182 = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _1eeef3a4 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _8bd66002 = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _753d4e63 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _84f977a2 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _0013c64e = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _d39ae2e4 = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _7ef6dca0 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _4aebe2f4 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _42e6ff62 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _1928f754 = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _06bbc592 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _4ee9923c,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _dd634c98,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _8fec7852,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _7a4eaf67,
    name: "calendar"
  }, {
    path: "/customer",
    component: _cca827f2,
    name: "customer"
  }, {
    path: "/details",
    component: _0236a28f,
    name: "details"
  }, {
    path: "/goals",
    component: _f41dd9e6,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _103c7ca8,
    name: "maisoku"
  }, {
    path: "/media",
    component: _0acb4231,
    name: "media"
  }, {
    path: "/privacy",
    component: _1c75995c,
    name: "privacy"
  }, {
    path: "/project",
    component: _03b21aa6,
    name: "project"
  }, {
    path: "/response",
    component: _76c9a0aa,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _1872ed87,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _682516bf,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _1889b82c,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _48711851,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _28af7847,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _f3f03998,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _f1699ad8,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _781a3ff1,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _94df266a,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _b44aad82,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _4c3a8c64,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _23f94714,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _d10adede,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _71514062,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _387f8e6d,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _266ddbca,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _f43bef5c,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _1afb1d7b,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _308de26e,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _58160826,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _53533f68,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _5be9b77c,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _716839d2,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _3ce16607,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _554058d7,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _2ff0c239,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _1a79428e,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _27bed885,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _038788cc,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _574444d3,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _6a063dd4,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _c38163bc,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _02536dcc,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _32d1aa34,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _0735316e,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _488bbb46,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _63fceb66,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _5a63f4d6,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _cbddbdac,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _2d32281c,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _76789162,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _c7fbe498,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _25f78722,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _45bca4e0,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _2b7c21d5,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _2e8ede40,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _08752a24,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _54edec12,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _b524af54,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _749847e4,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _94548182,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _1eeef3a4,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _8bd66002,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _753d4e63,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _84f977a2,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _0013c64e,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _d39ae2e4,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _7ef6dca0,
    name: "property-property",
    children: [{
      path: "contact",
      component: _4aebe2f4,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _42e6ff62,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _1928f754,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _06bbc592,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
